import dynamic from "next/dynamic";

import { useIsFirstRender } from "_common/hooks";
import { useGetDeviceType } from "_common/hooks/global";
import useMediaQuery        from "_common/hooks/useMediaQuery";
import Render               from "_components/atoms/render";
import { emotionCache }     from "_styles/createEmotionCache";

const RouterTransition = dynamic ( () => import ( "_components/atoms/routerTransition" ) );
const Analytics        = dynamic ( () => import ( "_analytics/index" ) );
const Schema           = dynamic ( () => import ( "_common/schema" ) );
const GlobalWrapper    = dynamic ( () => import ( "_components/globalWrapper" ) );
const Head             = dynamic ( () => import ( "_components/globalWrapper/appComponents/headModule" ) );
const ResetStore       = dynamic ( () => import ( "_components/globalWrapper/appComponents/resetStore" ) );
const ConnectIVSChat   = dynamic ( () => import ( "_components/globalWrapper/appComponents/connectIVSChat" ) );
const Shortcuts        = dynamic ( () => import ( "_components/globalWrapper/appComponents/shortcuts" ) );
const Toast            = dynamic ( () => import ( "_components/globalWrapper/appComponents/toast" ) );
const SetUserRoles     = dynamic ( () => import ( "_components/globalWrapper/appComponents/setUserRoles" ) );
const Layout           = dynamic ( () => import ( "_components/globalWrapper/appComponents/layout" ) );
const GoogleAuth       = dynamic ( () => import ( "_components/globalWrapper/appComponents/googleAuth" ) );
const AdConfig         = dynamic ( () => import ( "_components/globalWrapper/appComponents/adConfig" ) );
const FreeContent      = dynamic ( () => import ( "_components/globalWrapper/appComponents/freeContent" ) );
const HydrateAtoms     = dynamic ( () => import ( "_components/globalWrapper/appComponents/hydrateAtoms" ) );
const ThemeWrapper     = dynamic ( () => import ( "_components/globalWrapper/appComponents/themeWrapper" ) );
const AndroidFunctions = dynamic ( () => import ( "_common/webview/androidFns" ), { ssr: false } );

import "_common/translation/i18n";

// ---- Import styles here ----
import "@mantine/core/styles.css";
import "@mantine/carousel/styles.css";
import "_styles/common/Buttons.css";
import "_styles/common/carousel.css";
import "_styles/common/globals.css";
import "_styles/player/index.css";
import "video.js/dist/video-js.min.css";
import "videojs-ima/dist/videojs.ima.css";

const component = props => {
	const { Component, pageProps, router } = props;

	const isFirstRender = useIsFirstRender ();
	const deviceType    = useGetDeviceType ();
	const isMobile      = pageProps?.isMobile || deviceType === "mobile";
	const isWebview     = pageProps?.isWebview;

	useMediaQuery ();

	return (
		<>
			<Head isWebview = { isWebview } />

			<Render condition = { !pageProps?.noAnalytics }>
				<Analytics />
			</Render>

			<Schema />

			<Render condition = { pageProps?.shouldLogout }>
				<ResetStore />
			</Render>

			<SetUserRoles />

			<Render condition = { !isWebview }>
				<ConnectIVSChat broadcastData = { pageProps?.broadcastData } />

				<AdConfig />

				<FreeContent />
			</Render>

			<Render condition = { isWebview }>
				<AndroidFunctions
					isFirstPage = { pageProps?.isWebviewFirstPage }
					pathname    = { router.pathname }
				/>
			</Render>

			<Render condition = { isFirstRender }>
				<HydrateAtoms
					darkMode      = { pageProps?.darkMode }
					isMobile      = { pageProps?.isMobile }
					isWebview     = { pageProps?.isWebview }
					userAuth      = { pageProps?.userAuth }
					viewerCountry = { pageProps?.viewerCountry }
				/>
			</Render>

			<GoogleAuth withGoogleAuth = { isWebview ? false : true }>
				<ThemeWrapper
					darkMode     = { pageProps?.darkMode }
					emotionCache = { emotionCache }
					forceTheme   = { pageProps?.forceTheme }
					isMobile     = { isMobile }
					isWebview    = { isWebview }
					withTheme    = { true }
				>
					<Toast />

					<Render condition = { !isWebview }>
						<Shortcuts darkMode = { pageProps?.darkMode } />
					</Render>

					<Layout withLayout = { !pageProps?.noLayout }>

						<RouterTransition />

						<GlobalWrapper
							isMobile  = { isMobile }
							isWebview = { isWebview }
							load      = { pageProps?.noAuth ? false : true }
							userAuth  = { pageProps?.userAuth }
						>
							<Component
								{ ...pageProps }
								emotionCache             = { emotionCache }
								suppressHydrationWarning = { true }
							/>
						</GlobalWrapper>

					</Layout>
				</ThemeWrapper>
			</GoogleAuth>
		</>
	);
};

export default component;
